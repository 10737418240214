<template>
  <form v-if="inputData" @submit.prevent class="space-y-6 w-full">

    <div class="grid grid-cols-2 gap-6 pt-4">
      <div class="col-span-1">
        <Input name="name" placeholder="Filiāles nosaukums" v-model="form.name" :errors="errors.name" />
      </div>
    </div>

    <div class="grid grid-cols-2 gap-6 pt-4">
      <div class="col-span-1 flex items-center">
        <Checkbox text="Reģ. nr. atšķiras" v-model="form.differentRegNr" />
      </div>

      <template v-if="form.differentRegNr">
        <div class="col-span-1">
          <Input name="regNr" placeholder="Reģistrācijas nr." v-model="form.regNr" :errors="errors.regNr" />
        </div>
      </template>
    </div>

    <div class="grid grid-cols-2 gap-6 pt-4">

      <div class="col-span-1">
        <Input name="address" placeholder="Adrese" v-model="form.address" :errors="errors.address" />
      </div>

      <div class="col-span-1">
        <Input name="phone" placeholder="Telefona nr." v-model="form.phone" :errors="errors.phone" />
      </div>

      <div class="col-span-1">
        <Input name="email" placeholder="E-pasts" v-model="form.email" :errors="errors.email" />
      </div>

      <div class="col-span-1">
        <Radio name="contactType" v-model="form.contactType" :items="[{name: 'Jauna kont.', value: 'new'}, {name: 'Esoša', value: 'existing'}]" :errors="errors.contactType" />
      </div>

      <template v-if="form.contactType === 'existing'">
        <div class="col-span-1">
          <Select v-model="form.contact" :errors="errors.contact" :items="customer.contacts" placeholder="Kontaktpersona" />
        </div>
      </template>

      <template v-else>

        <div class="col-span-1">
          <Input name="contactName" placeholder="Kontaktpersonas vārds" v-model="form.contactName" :errors="errors.contactName" />
        </div>

        <div class="col-span-1">
          <Input name="contactPhone" placeholder="Kontaktpersonas telefons" v-model="form.contactPhone" :errors="errors.contactPhone" />
        </div>

        <div class="col-span-1">
          <Input name="contactEmail" placeholder="Kontaktpersonas e-pasts" v-model="form.contactEmail" :errors="errors.contactEmail" />
        </div>
      </template>

      <div class="col-span-1">
        <Select v-model="form.manager" :errors="errors.manager" :items="inputData.managers" placeholder="Menedžeris" />
      </div>

    </div>

    <div class="pt-2">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="hideAddForm">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Pievienot
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Input from "@/components/Components/Input";
import Checkbox from "@/components/Components/Checkbox";
import Radio from "@/components/Components/Radio";
import Select from "@/components/Components/Select";

export default {
  name: "AddNewBranch",
  components: {
    Input,
    Loading,
    Checkbox,
    Radio,
    Select,
  },
  props: ["customer"],
  data: () => ({
    form: {
      name: "",
      address: "",
      phone: "",
      email: "",
      regNr: "",
      contact: null,
      manager: null,
      differentRegNr: false,
      contactType: "new",
      contactName: "",
      contactPhone: "",
      contactEmail: "",
    },
  }),
  mounted() {
    this.$store.dispatch("getBranchInputData");
  },
  computed: {
    ...mapGetters({
      inputData: "branchInputData",
      loading: "loading",
      errors: "errors",
    }),
    setRegNr() {
      return this.differentRegNr ? this.regNr : "";
    },
    setInvoiceDeliveryEmail() {
      return this.invoiceDeliveryType
        ? this.invoiceDeliveryType.id === 2
          ? this.emailForInvoices
          : ""
        : "";
    },
    formValidation() {
      return {
        name: {
          rules: ['required']
        },
        regNr: {
          rules: [ this.form.differentRegNr ? 'required' : null]
        },
        address: {
          rules: ['required']
        },
        email: {
          rules: ['email']
        },
        contact: {
          rules: [this.form.contactType === 'existing' ? 'required' : null]
        },
        contactName: {
          rules: [this.form.contactType !== 'existing' ? 'required' : null]
        },
      }
    }
  },
  methods: {
    hideAddForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("createNewBranch", {
          data: {
            name: this.form.name,
            address: this.form.address,
            phone: this.form.phone,
            email: this.form.email,
            reg_nr: this.form.setRegNr,
            contact_id: this.contact ? this.contact.id : "",
            managing_user_id: this.manager ? this.manager.id : null,
            new_contact:
              this.contactType !== "new"
                ? null
                : {
                    name: this.contactName,
                    phone: this.contactPhone,
                    email: this.contactEmail,
                  },
          },
          customerId: this.customer.id,
        });
      } else {
        this.$Progress.fail();
      }
    },
  },
};
</script>

<style scoped>
</style>
