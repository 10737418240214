<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Filiāles
      </h3>

    </template>

    <template v-slot:buttons>
      <div class="flex gap-3">
        <template v-if="branches.length > 1">
          <Input name="search" placeholder="Meklēt" v-model="localSearch" :showPlaceholder="false" />

          <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" @click="showFullList = !showFullList" />
        </template>

        <template v-if="this.$userCan('manage_customers')">
          <Button icon="plus" @click="showForm('AddBranch')" />
        </template>
      </div>
    </template>
    <template v-slot:content>

      <template v-if="!displayForm('AddBranch')">
        <div v-if="branches.length > 0" class="flex flex-col w-full">
          <ShowBranch v-for="(branch, index) in allBranches" :key="index" :branch="branch" />
        </div>

        <div v-else class="w-full">
          <p class="text-center text-lg text-gray-400 mb-3">Klientam nav pievienotas filiāles</p>
          <button @click="showForm('AddBranch')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
            Pievienot
          </button>
        </div>
      </template>

      <template v-else>
        <AddNewBranch :customer="customer" />
      </template>

    </template>
  </item-card>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import Input from "@/components/Components/Input"
import ShowBranch from "@/components/Customer/Branches/ShowBranch"
import AddNewBranch from "@/components/Customer/Branches/AddNewBranch"

export default {
  name: "AllBranches",
  components: {
    ItemCard,
    Input,
    ShowBranch,
    AddNewBranch,
  },
  props: ['branches'],
  mounted() {
    this.showFullList ? this.allBranches = [...this.branches] : this.allBranches = [...this.branches].slice(0, 1)
  },
  data() {
    return {
      allBranches: [],
      localSearch: "",
      showFullList: false,
    }
  },
  computed: {
      ...mapGetters({
          customer: 'customer',
          formsForDisplay: 'formsForDisplay',
      }),
  },
  watch: {
    branches() {
      this.showFullList ? this.allBranches = [...this.branches] : this.allBranches = [...this.branches].slice(0, 1)
    },
    showFullList() {
      this.showFullList ? this.allBranches = [...this.branches] : this.allBranches = [...this.branches].slice(0, 1)
    },
    localSearch() {
      this.showFullList = true;
      this.allBranches = this.branches.filter(branch => {
        if (branch.address.toLowerCase().includes(this.localSearch.toLowerCase())
        || branch.name.toLowerCase().includes(this.localSearch.toLowerCase())
        || (branch.region && String(branch.region).toLowerCase().includes(this.localSearch.toLowerCase()))) {
          return branch;
        }
      })
    },
  },
  methods: {
      showForm(formName){
          this.$store.dispatch('addFormForDisplay', formName)
      },
      displayForm(formName){
          return this.formsForDisplay.includes(formName)
      },
  }
}
</script>

<style>

</style>